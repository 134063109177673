(function($) {

  $( document ).ready(function() {

    if ($('body').hasClass('wp-admin')) { // backend begin

      if ($('#event-time.postbox').length) { // check if meta box exists

        $.datetimepicker.setDateFormatter({
          parseDate: function (date, format) {
            var d = moment(date, format);
            return d.isValid() ? d.toDate() : false;
          },

          formatDate: function (date, format) {
            return moment(date).format(format);
          }
        });

        $('.js-event-time-start').datetimepicker({
          format:'MMMM D, YYYY, h:mm a',
          formatTime:'h:mm a',
          formatDate:'DD.MM.YYYY',
          scrollMonth: false,
          scrollTime: false,
          scrollInput: false,
          step: 30,
          onChangeDateTime: function (current_time) {
            $('.js-event-time-ts-start').val(moment(current_time).format('X'));
          },
          onGenerate: function( ct, $input ){
            $input.prop('readonly', true);
            var _this = $(this);
            _this.find('.xdsoft_date').removeClass('xdsoft_disabled');
            _this.find('.xdsoft_time').removeClass('xdsoft_disabled');
          }
        });

        $('.js-event-time-end').datetimepicker({
          format:'MMMM D, YYYY, h:mm a',
          formatTime:'h:mm a',
          formatDate:'DD.MM.YYYY',
          scrollMonth: false,
          scrollTime: false,
          scrollInput: false,
          step: 30,
          onChangeDateTime: function (current_time) {
            $('.js-event-time-ts-end').val(moment(current_time).format('X'));
          },
          onGenerate: function( ct, $input ){
            $input.prop('readonly', true);
            var _this = $(this);
            _this.find('.xdsoft_date').removeClass('xdsoft_disabled');
            _this.find('.xdsoft_time').removeClass('xdsoft_disabled');
          }
        });

      }

    } else { // front end begin

    }

  });

})(jQuery);
