/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        var num = 125; //number of pixels before modifying styles
        $(window).bind("scroll", function () {
          if ($(window).scrollTop() > num) {
            $("body").addClass("body-scrolled");
          } else {
            $("body").removeClass("body-scrolled");
          }
        });

        // $(".dropdown-logo").hover(function(){
        //   $(".dropdown-menulist").addClass("active");
        // }, function(){
        // $(".dropdown-menulist").removeClass("active");
        //});

        $(".dropdown-menu").removeClass("slider-dropdown");
        $(".menu-side .bar-container").click(function () {
          $(".dropdown-menu").toggleClass("slider-dropdown");
          $(".holder-dropdown").toggleClass("show");
          $(this).toggleClass("open-dropdown");
        });

        $(".sub-arrow").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(this).siblings(".sub-pages").toggleClass("tap");
        });

        $(".timelinebutton").click(function () {
          $(".modal-timeline").addClass("active");
        });

        $(".close-timeline").click(function () {
          $(".modal-timeline").removeClass("active");
        });

        $(".scroll-col").click(function () {
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop: $($(this).attr("id")).offset().top - 160,
              },
              800
            );
          return false;
        });

        $(".arrow-overlay").click(function () {
          $(this).css("display", "none");
          $(".modal-overlay").addClass("active");
          $(".arrow-close-overlay").addClass("active");
          $(".banner").css("overflow", "unset");
        });

        $(".arrow-close-overlay").click(function () {
          $(".arrow-overlay").css("display", "block");
          $(".modal-overlay").removeClass("active");
          $(this).removeClass("active");
          $(".banner").css("overflow", "hidden");
        });

        $(".close-overlay").click(function () {
          $(".arrow-overlay").css("display", "block");
          $(".modal-overlay").removeClass("active");
          $(".arrow-close-overlay").removeClass("active");
          $(".banner").css("overflow", "hidden");
        });
        $(".share-button-holder").addClass("hide-item");
        $(".pop-summary").click(function () {
          $(".approach").removeClass("show-popup");
          $(".services").removeClass("show-popup");
          $(".inquiry").removeClass("show-popup");
          $(".summary").addClass("show-popup");
          $(".summary-box").removeClass("display-popup");
          $(".main-summary").addClass("display-popup");
          $(".overlay-content").addClass("hide-item");
          $(".popuplink").removeClass("active");
          $(".pop-summary").addClass("active");
          $(".menu-fixed").removeClass("show-item");
          $(".menu-fixed").addClass("hide-item");
          $("footer").removeClass("show-item");
          $("footer").addClass("hide-item");
          $(".share-button-holder").removeClass("hide-item");
          $(".share-button-holder").addClass("show-item");
        });

        $(".pop-inquiry").click(function () {
          $(".summary").removeClass("show-popup");
          $(".approach").removeClass("show-popup");
          $(".services").removeClass("show-popup");
          $(".inquiry").addClass("show-popup");
          $(".summary-box").removeClass("display-popup");
          $(".main-inquiry").addClass("display-popup");
          $(".overlay-content").addClass("hide-item");
          $(".popuplink").removeClass("active");
          $(".pop-inquiry").addClass("active");
          $(".menu-fixed").removeClass("show-item");
          $(".menu-fixed").addClass("hide-item");
          $("footer").removeClass("show-item");
          $("footer").addClass("hide-item");
          $(".share-button-holder").removeClass("hide-item");
          $(".share-button-holder").addClass("show-item");
        });

        $(".pop-services").click(function () {
          $(".summary").removeClass("show-popup");
          $(".approach").removeClass("show-popup");
          $(".inquiry").removeClass("show-popup");
          $(".services").addClass("show-popup");
          $(".summary-box").removeClass("display-popup");
          $(".main-services").addClass("display-popup");
          $(".overlay-content").addClass("hide-item");
          $(".popuplink").removeClass("active");
          $(".pop-services").addClass("active");
          $(".menu-fixed").removeClass("show-item");
          $(".menu-fixed").addClass("hide-item");
          $("footer").removeClass("show-item");
          $("footer").addClass("hide-item");
          $(".share-button-holder").removeClass("hide-item");
          $(".share-button-holder").addClass("show-item");
        });

        $(".pop-approach").click(function () {
          $(".summary").removeClass("show-popup");
          $(".services").removeClass("show-popup");
          $(".inquiry").removeClass("show-popup");
          $(".approach").addClass("show-popup");
          $(".summary-box").removeClass("display-popup");
          $(".main-approach").addClass("display-popup");
          $(".overlay-content").addClass("hide-item");
          $(".popuplink").removeClass("active");
          $(".pop-approach").addClass("active");
          $(".menu-fixed").removeClass("show-item");
          $(".menu-fixed").addClass("hide-item");
          $("footer").removeClass("show-item");
          $("footer").addClass("hide-item");
          $(".share-button-holder").removeClass("hide-item");
          $(".share-button-holder").addClass("show-item");
        });

        $(".close").click(function () {
          $(".summary").removeClass("show-popup");
          $(".approach").removeClass("show-popup");
          $(".services").removeClass("show-popup");
          $(".inquiry").removeClass("show-popup");
          $(".summary-box").removeClass("display-popup");
          $(".popuplink").removeClass("active");
          $(".overlay-content").removeClass("hide-item");
          $(".menu-fixed").addClass("show-item");
          $(".menu-fixed").removeClass("hide-item");
          $("footer").addClass("show-item");
          $("footer").removeClass("hide-item");
          $(".share-button-holder").addClass("hide-item");
          $(".share-button-holder").removeClass("show-item");
        });

        // $(".dropdown-logo").mouseover(function () {
        //   $(".dropdown-menulist").addClass("active");
        //   $(".dropdown-menulist").addClass("index-top");
        // });

        // $(".dropdown-menulist").mouseover(function () {
        //   $(".dropdown-menulist").addClass("active");
        //   $(".dropdown-menulist").addClass("index-top");
        // });
        // $(".dropdown-menulist").mouseout(function () {
        //   $(".dropdown-menulist").removeClass("active");
        //   $(".dropdown-menulist").removeClass("index-top");
        // });

        $('#filter').submit(function(){
          var filter = $('#filter');
          $.ajax({
            url:filter.attr('action'),
            data:filter.serialize(), // form data
            type:filter.attr('method'), // POST
            beforeSend:function(xhr){
              filter.find('button').text('Processing...'); // changing the button label
            },
            success:function(data){
              filter.find('button').text('Apply filter'); // changing the button label back
              $('#response').html(data); // insert data
            }
          });
          return false;
        });
        
        // SLICK HEROES
        $(".js-slick-heroes").slick({
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 500,
              settings: {
                dots: true,
                arrows: false,
              },
            },
          ],
        });

         $(".js-timeline-dates").slick({
          slidesToShow: 7,
          slidesToScroll: 1,
          asNavFor: ".js-timeline",
          arrows: true,
          prevArrow:'<span class="slick-prev"><i class="fas fa-chevron-double-left primary"></i></span>',
          nextArrow: '<span class="slick-next"><i class="fas fa-chevron-double-right primary"></i></span>',
          draggable: true,
          centerMode: false,
          infinite: false,
          dots: false, 
          speed: 700,
          autoplay: false,
          autoplaySpeed: 7000,
          focusOnSelect: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 6
              },
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 5
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 3
              },
            }
          ],
        });

        $(".js-timeline").slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 500,
          autoplay: false,
          autoplaySpeed: 5000,
          asNavFor: ".js-timeline-dates",
          arrows: false,
          dots: false,
          infinite: false,
          draggable: true,
          centerMode: false,
          focusOnSelect: true,
          adaptiveHeight: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4
              },
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 3
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1
              },
            }
          ],
        });



        $(".js-loadmore-posts").click(function () {
          var button = $(this),
            data = {
              action: "loadmore",
              query: loadmore_posts_params.posts, // that's how we get params from wp_localize_script() function
              page: loadmore_posts_params.current_page,
            };

          $.ajax({
            url: loadmore_posts_params.ajaxurl, // AJAX handler
            data: data,
            type: "POST",
            beforeSend: function (xhr) {
              button.html('<span class="button primary">Loading...</span>'); // change the button text, you can also add a preloader image
            },
            success: function (data) {
              if (data) {
                button
                  .html('<span class="button primary">More posts</span>')
                  .before(data); // insert new posts
                loadmore_posts_params.current_page++;

                if (
                  loadmore_posts_params.current_page ===
                  loadmore_posts_params.max_page
                ) {
                  button.remove(); // if last page, remove the button
                }

                // you can also fire the "post-load" event here if you use a plugin that requires it
                // $( document.body ).trigger( 'post-load' );
              } else {
                button.remove(); // if no data, remove the button as well
              }
            },
          });
        });

        $(".js-select2").select2({
          minimumResultsForSearch: Infinity,
        });

        $(".js-select2-search").select2();

        $(".js-selector-with-url").change(function () {
          location.href = $(this).val();
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var $body = $("body");

        //Toggle Filter Options
        $(".js-filter-expand").click(function () {
          $(".js-filter-expander").toggleClass("expanded");
        });

        //Toggle menu
        $(".js-menu-open").click(function () {
          $("body").toggleClass("menu-off-canvas-active");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        $(".js-menu-open-left").click(function () {
          $body.toggleClass("menu-off-canvas-active");
          $body.toggleClass("left");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        //Toggle Feedback
        $(".js-popup-feedback").click(function () {
          $body.toggleClass("popup-active-feedback");
          $body.removeClass("fb-messenger-active");
        });

        //Toggle FB Messenger
        $(".js-fb-messenger").click(function () {
          $body.toggleClass("fb-messenger-active");
          $body.removeClass("menu-off-canvas-active");
        });

        //Toggle Search
        $(".js-popup-search").click(function (e) {
          e.preventDefault();
          $(".popup-wrap-search input").focus();
          $body.toggleClass("popup-active-search");
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        $("#slider-scroll").addClass("show-div");
        $(".banner").addClass("hide-div");
        $(".menu-fixed").addClass("hide-item");
        $("footer").addClass("hide-item");
        $("#scroll-button").click(function () {
          $("#slider-scroll").removeClass("show-div");
          $(".banner").removeClass("hide-div");
          $("#slider-scroll").addClass("hide-div");
          $(".banner").addClass("show-div");
          $(".menu-fixed").removeClass("hide-item");
          $("footer").removeClass("hide-item");
          $(".menu-fixed").addClass("show-item");
          $("footer").addClass("show-item");
        });

        $(".js-slider-for").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          infinite: true,

          customPaging: function(slider, i) {
            // $icon =
            //   "." +
            //   $(slider.$slides[i]).attr("class") +
            //   " .tile-slide-nav .entry-summary span";
            $(".js-slider-nav .tile-slide-nav").attr("class");
            var counter = i + 1;
            var $com = ".slick-slide:nth-child(" + counter + ") h2";
            // var $icon = $($com).html();
            // return "<h2>" + $icon + "</h2>";
          },
          prevArrow:'<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:'<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          fade: true,
          asNavFor: ".js-slider-nav",
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 480,
              settings: {
                dots: true,
                customPaging: function (slider, i) {
                  // var title = $(slider.$slides[i]).data('title');
                  return "";
                },
              },
            },
          ],
        });

        $(".js-slider-nav").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: ".js-slider-for",
          arrows: false,
          centerMode: false,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          infinite: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
          ],
        });

        $(".banner-nav .slick-slide h3").click(function (e) {
          e.preventDefault();
          $(".holder_content .content").removeClass("show-content");
          var element = document.getElementById("content");
          // void element.offsetWidth;
          $(".holder_content .content").addClass("show-content");
        });

        var $landingWrapper = $(".landing-wrapper"),
            $landingInnerContent = $(".landing-inner-content");

        // set initial container to half of .landing-inner-content width
        //TweenMax.set($landingWrapper, {scrollTo: {x: $landingInnerContent.width()/4}, ease: Power2.easeOut});

        // scroll left and right
        $landingInnerContent.on("mousemove touchmove", function(e) {
          if (e.clientX > $landingWrapper.width() / 2) {
            TweenMax.to($landingWrapper, 2, {
              scrollTo: {
                x: "+=175"
              },
              ease: Power2.easeOut
            });
          } else {
            TweenMax.to($landingWrapper, 2, {
              scrollTo: {
                x: "-=175"
              },
              ease: Power2.easeOut
            });
          }
        });
      },

      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    tax_project_categories: {
      init: function () {
        $(document).on('click', '.dropbtn-tax', function() {
          $(this).toggleClass('expand');
          $('.dropdown-content-tax').toggleClass('active'); //you can list several class names
        });
      }
    },
    project: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    overview: {
      init: function () {
        // JavaScript to be fired on the our-story page
        $(".js-slider-for").slick({
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow:
            '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          pauseOnHover: true,
          fade: true,
          centerMode: true,
          infinite: true,
          asNavFor: ".js-slider-nav",
          adaptiveHeight: true,
        });

        $(".js-slider-nav").slick({
          // autoplay: true,
          // autoplaySpeed: 2000,
          slidesToShow: 2,
          slidesToScroll: 1,
          asNavFor: ".js-slider-for",
          dots: false,
          arrows: false,
          centerMode: false,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          infinite: true,
          pauseOnHover: true,
          responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
              },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ],
        });

        $(".leftArrow").on("click", function () {
          $(".js-slider-for").slick("slickPrev");
          $(".slick-active:eq(2)").css("opacity", "1");
          $(".slick-active:eq(3)").css("opacity", "0.75");
          $(".slick-active:eq(4)").css("opacity", "0.5");
          $(".slick-active:eq(5)").css("opacity", "0.25");
        });
        $(".rightArrow").on("click", function () {
          $(".js-slider-for").slick("slickNext");
          $(".slick-active:eq(2)").css("opacity", "1");
          $(".slick-active:eq(3)").css("opacity", "0.75");
          $(".slick-active:eq(4)").css("opacity", "0.5");
          $(".slick-active:eq(5)").css("opacity", "0.25");
        });
        $(".slick-active:eq(2)").css("opacity", "1");
        $(".slick-active:eq(3)").css("opacity", "0.75");
        $(".slick-active:eq(4)").css("opacity", "0.5");
        $(".slick-active:eq(5)").css("opacity", "0.25");
        $(".slick-slide").on("click", function () {
          $(".slick-active:eq(2)").css("opacity", "1");
          $(".slick-active:eq(3)").css("opacity", "0.75");
          $(".slick-active:eq(4)").css("opacity", "0.5");
          $(".slick-active:eq(5)").css("opacity", "0.25");
        });
      },

      finalize: function () {
        // JavaScript to be fired on the our-story page, after the init JS
      },
    },
    contact: {
      init: function () {
        //JS to flip Card
        // var card = document.querySelector('.card');
        // var card1 = document.querySelector('#card');
        // card1.addEventListener( 'click', function() {
        //   card.classList.toggle('is-flipped');
        // });
        // JavaScript to be fired on the contact page
        // var gmap_id = $(".gmapcontact"),
        //   zoom = gmap_id.data("zoom") || 18,
        //   address = gmap_id.data("address") || "NL, Canada",
        //   geocoder = new google.maps.Geocoder();
        //
        // geocoder.geocode({ address: address }, function (results, status) {
        //   if (status === google.maps.GeocoderStatus.OK) {
        //     var centerLL = results[0].geometry.location;
        //
        //     var map = new google.maps.Map(gmap_id.get(0), {
        //       center: centerLL,
        //       scrollwheel: false,
        //       zoom: zoom || 18,
        //     });
        //     new google.maps.Marker({
        //       map: map,
        //       position: centerLL,
        //       title: results[0].formatted_address,
        //     });
        //   } else {
        //     console.log(
        //       "Geocode was not successful for the following reason: " + status
        //     );
        //     $(".gmapcontact").html(
        //       '<div class="blurb no-results"><h3>Google Maps is not available due to unavailable location data.</h3></div>'
        //     );
        //   }
        // });
      },
    },
    team: {
      init: function () {
        // JavaScript to be fired on the contact page
        $(".middle-arrow").fadeOut();
        $(".plus-minus-toggle").on("click", function () {
          // $(".tile-team-member .plus-minus-toggle").addClass("collapsed");
          $(this).toggleClass("collapsed");
          $(this).closest(".tile-team-member").toggleClass("active");
          // $(".tile-team-member").removeClass("active");
          // $(".tile-team-member").find(".middle-arrow")
          //     .delay(400)
          //     .fadeOut();
          $(this)
            .closest(".tile-team-member")
            .find(".middle-arrow")
            .delay(400)
            .fadeToggle();
        });

        $(".tile-team-member").fadeOut();
        //$(".tile-team-member").removeClass("tile-active");
        $(".team_member_categories-leadership").addClass("tile-cat");
        $(".tile-cat:eq(0)").addClass("tile-top");
        $(".tile-cat:eq(1)").addClass("tile-top");
        // $(".tile-cat:eq(2)").addClass("tile-top");
        // $(".tile-cat:eq(3)").addClass("tile-top");
        $(".team_member_categories-leadership").fadeIn();

        $(".categories-members").on("click", function () {
          $('html, body').stop().animate({
            scrollTop: $("#mainwrap").offset().top - 160
          }, 300);
          $(".title-category").removeClass("active-cat");
          $(".title-" + this.id).addClass("active-cat");
          $(".categories-members").removeClass("active");
          $(this).addClass("active");
          $(".tile-team-member").fadeOut();
          $(".tile-team-member").removeClass("tile-cat");
          $(".tile-team-member").removeClass("tile-active");
          $("." + this.id).addClass("tile-cat");
          var $cat = "." + this.id;
          $(".team_member_categories-leadership:eq(0)").addClass("tile-top");
          $(".team_member_categories-leadership:eq(1)").addClass("tile-top");
          // $(".team_member_categories-leadership:eq(2)").addClass("tile-top");
          // $(".team_member_categories-leadership:eq(3)").addClass("tile-top");
          $("." + this.id).fadeIn();
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

$(window).on("load",function() {
  $(window).scroll(function() {
    var windowBottom = $(this).scrollTop() + $(this).innerHeight();
    $(".fade").each(function() {
      /* Check the location of each desired element */
      var objectBottom = $(this).offset().top + $(this).outerHeight();

      /* If the element is completely within bounds of the window, fade it in */
      if (objectBottom < windowBottom) { //object comes into view (scrolling down)
        if ($(this).css("opacity")==0) {$(this).fadeTo(500,1);}
      } else { //object goes out of view (scrolling up)
        if ($(this).css("opacity")==1) {$(this).fadeTo(500,0);}
      }
    });
  }).scroll(); //invoke scroll-handler on page-load
});
